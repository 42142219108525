import React from "react";
import {
    Heading,
    Paragraph,
    UnorderedList,
    OrderedList,
    ListItem
} from "@piggybank/core";
import { useTranslation } from "react-i18next";

const CreditCardTCs = () => {
    const { t } = useTranslation();

    const KFS_MPD_DESC = <Paragraph>{t('ICO.CREDIT.KFS_MPD_DESC')}</Paragraph>;
    const KFS_FEES_AF_CT_KEY = <ListItem><strong dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_AF_CT_KEY') }} /></ListItem>;
    const KFS_FEES_AF_PC_KEY = <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_AF_PC_KEY')}</strong></ListItem>;
    const KFS_FEES_AF_WP = <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_AF_WP')}</strong></ListItem>;
    const KFS_FEES_AF_ACSB_KEY = <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_AF_ACSB_KEY')}</strong></ListItem>;
    const KFS_FEES_AF_NA = <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_NA')}</ListItem>;
    const KFS_FEES_AF_ACCB_KEY = <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_AF_ACCB_KEY')}</strong></ListItem>;
    const KFS_FEES_HKD300 = <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD300')}</strong></ListItem>;

    return (
        <>
            {/****************Key Facts Statement******************/}
            <Heading level={2} accentBar>{t('ICO.CREDIT.KFS_TITLE')}</Heading>
            <Heading level={3} marginBottom={4}>{t('ICO.CREDIT.KFS_DESC3')}</Heading>
            <Heading level={3} marginBottom={4}>{t('ICO.CREDIT.KFS_DESC1')}</Heading>
            <Paragraph>{t('ICO.CREDIT.KFS_DESC2')}</Paragraph>
            <Paragraph>{t('ICO.CREDIT.KFS_DESC4')}<br/>{t('ICO.CREDIT.KFS_DESC5')}</Paragraph>
            <Heading level={3} marginBottom={4}>{t('ICO.CREDIT.KFS_IR&FC')}</Heading>
            <UnorderedList type="decimal">
                <ListItem>
                    <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_IR&FC1') }} />
                    <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_IR&FC1_DESC') }} />
                    <UnorderedList>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC1_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC1_2')}
                            <OrderedList type="lower-alpha">
                                <ListItem>{t('ICO.CREDIT.KFS_IR&FC1_2_1')}</ListItem>
                                <ListItem>{t('ICO.CREDIT.KFS_IR&FC1_2_2')}</ListItem>
                            </OrderedList>
                        </ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC1_3')}</ListItem>
                    </UnorderedList>
                </ListItem>
                <ListItem>
                    <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_IR&FC2') }} />
                    <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_IR&FC2_DESC') }} />
                    <UnorderedList>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_1')}</ListItem>
                        <ListItem>
                            <Paragraph>{t('ICO.CREDIT.KFS_IR&FC2_2')}</Paragraph>
                            <OrderedList type="lower-alpha">
                                <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_2_1')}</ListItem>
                                <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_2_2')}</ListItem>
                            </OrderedList>
                        </ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_3')}</ListItem>
                    </UnorderedList>
                    <Paragraph>{t('ICO.CREDIT.KFS_IR&FC2_R')}</Paragraph>
                    <OrderedList>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_R1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_R2')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_IR&FC2_R3')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.KFS_IR&FC3')}</Paragraph>
                    <UnorderedList>
                        <ListItem>{t('ICO.CREDIT.NA')}</ListItem>
                    </UnorderedList>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.KFS_IR&FC4')}</Paragraph>
                    <UnorderedList>
                        <ListItem>
                            <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_IR&FC4_1') }} />
                        </ListItem>
                    </UnorderedList>
                </ListItem>
            </UnorderedList>
            {/****************Minimum Payment Due******************/}
            <Heading level={3} marginBottom={4}>{t('ICO.CREDIT.KFS_MPD')}</Heading>
            <UnorderedList>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.KFS_MPD1')}</Paragraph>
                    {KFS_MPD_DESC}
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.KFS_MPD1_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_MPD1_2')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_MPD2') }} />
                    {KFS_MPD_DESC}
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.KFS_MPD2_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.KFS_MPD2_2')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.KFS_MPD3')}</Paragraph>
                    {KFS_MPD_DESC}
                    <UnorderedList type="none">
                        <ListItem>{t('ICO.CREDIT.KFS_MPD3_1')}</ListItem>
                    </UnorderedList>
                </ListItem>
            </UnorderedList>
            {/****************Fees******************/}
            <Heading level={3} marginBottom={4}>{t('ICO.CREDIT.KFS_FEES')}</Heading>
            {/****************Fees >> Annual Fee******************/}
            <Heading level={4} marginBottom={5}>{t('ICO.CREDIT.KFS_FEES_AF')}</Heading>
            <Paragraph>{t('ICO.CREDIT.KFS_FEES_AF_DESC')}</Paragraph>
            <UnorderedList>
                <ListItem>
                    {/*****************Fees >> Annual Fee >> HK dollar personal credit cards ******************/}
                    <Paragraph>{t('ICO.CREDIT.KFS_FEES_AF1')}</Paragraph>
                    <UnorderedList className="table light-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_HPM')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        {KFS_FEES_AF_WP}
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        {KFS_FEES_AF_WP}
                    </UnorderedList>
                    <UnorderedList className="table dark-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_HRCC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        {KFS_FEES_AF_WP}
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        {KFS_FEES_AF_WP}
                    </UnorderedList>
                    <UnorderedList className="table light-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_HVSC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD2K')}</strong></ListItem>
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD1K')}</strong></ListItem>
                    </UnorderedList>
                    <UnorderedList className="table dark-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem><span dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_AF_CT_VPC1') }}></span></ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD2K')}</strong></ListItem>
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_AF_NA')}</strong></ListItem>
                    </UnorderedList>
                    <UnorderedList className="table light-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_VPC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD1800')}</strong></ListItem>
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD900')}</strong></ListItem>
                    </UnorderedList>
                    <UnorderedList className="table dark-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_VGGMC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD600')}</strong></ListItem>
                        {KFS_FEES_AF_ACSB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD600')}</strong></ListItem>
                        {KFS_FEES_AF_ACCB_KEY}
                        {KFS_FEES_HKD300}
                    </UnorderedList>
                    <UnorderedList className="table light-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_VMC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        {KFS_FEES_HKD300}
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_HKD300}
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD150')}</strong></ListItem>
                    </UnorderedList>
                    <UnorderedList className="table dark-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_ICC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        {KFS_FEES_HKD300}
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        {KFS_FEES_AF_NA}
                    </UnorderedList>
                </ListItem>
                <ListItem>
                    {/*****************Fees >> Foreign currency credit card  ******************/}
                    <Paragraph>{t('ICO.CREDIT.KFS_FEES_AF2')}</Paragraph>
                    <UnorderedList className="table light-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_USVGC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_USD80')}</strong></ListItem>
                        {KFS_FEES_AF_ACSB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_USD80')}</strong></ListItem>
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_USD40')}</strong></ListItem>
                    </UnorderedList>
                </ListItem>
                <ListItem>
                    {/*****************Fees >> UnionPay dual currency credit card******************/}
                    <Paragraph>{t('ICO.CREDIT.KFS_FEES_AF3')}</Paragraph>
                    <UnorderedList className="table light-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem><span dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_AF_CT_HPUDCDC') }} /></ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD1800')}</strong></ListItem>
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD900')}</strong></ListItem>
                    </UnorderedList>
                    <UnorderedList className="table dark-color" type="none">
                        {KFS_FEES_AF_CT_KEY}
                        <ListItem>{t('ICO.CREDIT.KFS_FEES_AF_CT_UDCC')}</ListItem>
                        {KFS_FEES_AF_PC_KEY}
                        {KFS_FEES_HKD300}
                        {KFS_FEES_AF_ACSB_KEY}
                        {KFS_FEES_AF_NA}
                        {KFS_FEES_AF_ACCB_KEY}
                        <ListItem><strong>{t('ICO.CREDIT.KFS_FEES_HKD150')}</strong></ListItem>
                    </UnorderedList>
                </ListItem>
            </UnorderedList>
            {/****************Fees >> Cash Advance Fee and Handling Fee******************/}
            <Heading level={4} marginBottom={5}>{t('ICO.CREDIT.KFS_FEES_CAF&HF')}</Heading>
            <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_CAF&HF_DESC') }} />
            <UnorderedList>
                <ListItem>{t('ICO.CREDIT.KFS_FEES_CAF&HF_1')}</ListItem>
                <ListItem>{t('ICO.CREDIT.KFS_FEES_CAF&HF_2')}</ListItem>
            </UnorderedList>
            {/****************Fees >> Foreign Currency Transaction Fee3******************/}
            <Heading level={4} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_FCTF') }} />
            <Heading level={5}>{t('ICO.CREDIT.KFS_FEES_FCTF1')}</Heading>
            <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_FCTF1_DESC') }} />
            <Heading level={5}>{t('ICO.CREDIT.KFS_FEES_FCTF2')}</Heading>
            <Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_FCTF2_DESC') }} />
            {/****************Fees >> Fee relating to Settling Foreign Currency Transaction in the currency of the credit card ******************/}
            <Heading level={4} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_FSFCT') }} />
            <Paragraph>{t('ICO.CREDIT.KFS_FEES_FSFCT_DESC')}</Paragraph>
            {/****************Fees >> Late Charge ******************/}
            <Heading level={4}>{t('ICO.CREDIT.KFS_FEES_LC')}</Heading>
            <Paragraph>{t('ICO.CREDIT.KFS_FEES_LC_DESC')}</Paragraph>
            <UnorderedList className="indent">
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_LC_1') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_LC_2') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_LC_3') }} /></ListItem>
            </UnorderedList>
            {/****************Fees >> Overlimit Handling Fee *****************/}
            <Heading level={4} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_OHF') }}></Heading>
            <Paragraph>{t('ICO.CREDIT.KFS_FEES_OHF_DESC')}</Paragraph>
            <UnorderedList className="indent">
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_OHF_1') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_OHF_2') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_OHF_3') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_OHF_4') }} /></ListItem>
            </UnorderedList>
            {/****************Fees >> Returned Cheque / Rejected Autopay Handling Fee *****************/}
            <Heading level={4}>{t('ICO.CREDIT.KFS_FEES_RCRAHF')}</Heading>
            <Paragraph>{t('ICO.CREDIT.KFS_FEES_RCRAHF_DESC')}</Paragraph>
            <UnorderedList className="indent">
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_RCRAHF_1') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_RCRAHF_2') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_RCRAHF_3') }} /></ListItem>
            </UnorderedList>
            {/****************Annual Paper Statement Fee*****************/}
            <Heading level={4} dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_YRCRAHF') }}></Heading>
            <Paragraph>{t('ICO.CREDIT.KFS_FEES_YRCRAHF1')}</Paragraph>
            <UnorderedList className="indent">
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_YRCRAHF2') }} /></ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.KFS_FEES_YRCRAHF3') }} /></ListItem>
            </UnorderedList>
            {/****************Remarks*****************/}
            <Heading level={4}>{t('ICO.CREDIT.KFS_REMARKS')}</Heading>
            <OrderedList>
                <ListItem>{t('ICO.CREDIT.KFS_REMARKS_1')}</ListItem>
                <ListItem>{t('ICO.CREDIT.KFS_REMARKS_2')}</ListItem>
                <ListItem>{t('ICO.CREDIT.KFS_FEES_LCIOPS')}</ListItem>
                <ListItem>{t('ICO.CREDIT.KFS_REMARKS_3')}</ListItem>
                <ListItem>{t('ICO.CREDIT.KFS_REMARKS_4')}</ListItem>
                <ListItem>{t('ICO.CREDIT.KFS_FEES_LCIOPS1')}</ListItem>
            </OrderedList>
            <Paragraph marginBottom={6}>{t('ICO.CREDIT.KFS_DECLARE')}</Paragraph>
            {/**************************************** Terms and Conditions ******************************************/}
            <Heading level={2} accentBar>{t('ICO.CREDIT.TC')}</Heading>
            <Paragraph>{t('ICO.CREDIT.TC_DESC1')}</Paragraph>
            <Paragraph>{t('ICO.CREDIT.TC_DESC2')}</Paragraph>
            <Paragraph>{t('ICO.CREDIT.TC_A')}</Paragraph>
            <OrderedList type="lower-alpha">
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.TC_B')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_B_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_B_2')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_B_3')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_B_4')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_B_5')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_B_6')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_B_7')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.TC_C')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_C_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_C_2')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_C_3')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_C_4')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_C_5')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_C_6')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem><Paragraph dangerouslySetInnerHTML={{ __html: t('ICO.CREDIT.TC_D') }}/></ListItem>
                <ListItem>{t('ICO.CREDIT.TC_E')}</ListItem>
                <ListItem>{t('ICO.CREDIT.TC_F')}</ListItem>
                <ListItem>
                    <Paragraph> {t('ICO.CREDIT.TC_G')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_G_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_G_2')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_G_3')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_G_4')}</ListItem>
                    </OrderedList>
                    <Paragraph>{t('ICO.CREDIT.TC_G_5')}</Paragraph>
                </ListItem>
                <ListItem>{t('ICO.CREDIT.TC_H')}</ListItem>
                <ListItem>{t('ICO.CREDIT.TC_I')}</ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.TC_J')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_J_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_J_2')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.TC_K')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_K_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_K_2')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>{t('ICO.CREDIT.TC_L')}</ListItem>
                <ListItem>{t('ICO.CREDIT.TC_M')}</ListItem>
                <ListItem>{t('ICO.CREDIT.TC_N')}</ListItem>
                <ListItem>{t('ICO.CREDIT.TC_O')}</ListItem>
                <ListItem>{t('ICO.CREDIT.TC_P')}</ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.TC_Q')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_Q_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_Q_2')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_Q_3')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_Q_4')}</ListItem>
                    </OrderedList>
                    <Paragraph>{t('ICO.CREDIT.TC_Q_5')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_Q_6')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_Q_7')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_Q_8')}</ListItem>
                    </OrderedList>
                </ListItem>
                <ListItem>
                    <Paragraph>{t('ICO.CREDIT.TC_R')}</Paragraph>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_R_1')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_R_2')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_R_3')}</ListItem>
                    </OrderedList>
                    <OrderedList type="lower-roman" style={{ 'paddingLeft': '25px' }}>
                        <ListItem>{t('ICO.CREDIT.TC_R_4')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_R_5')}</ListItem>
                        <ListItem>{t('ICO.CREDIT.TC_R_6')}</ListItem>
                    </OrderedList>
                    <OrderedList type="lower-roman">
                        <ListItem>{t('ICO.CREDIT.TC_R_7')}</ListItem>
                    </OrderedList>
                    <Paragraph>{t('ICO.CREDIT.TC_R_8')}</Paragraph>
                </ListItem>
            </OrderedList>
            <Paragraph>{t('ICO.CREDIT.TC_DEF_DATE')}</Paragraph>
        </>
    )
}

export default CreditCardTCs;

